import {
  CountryCode,
  PaymentCategory,
  RecipientType,
  RecursivePartial,
  TaxProfileClassification,
  TaxProfileTinData,
  TaxStatus,
  formatCountry,
  getRegionLabel,
} from "@trolley/common-frontend";
import { Tabs } from "antd";
import assignDeep from "assign-deep";
import { PATHS } from "pages/App/routes";
import OnboardingHeader, { OnboardingStatus } from "pages/Onboarding/OnboardingHeader";
import OnboardingWrapper from "pages/Onboarding/OnboardingWrapper";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { notifyError } from "store/actions/notifications";
import { TaxProfile, createTaxProfile, signAndSubmitTaxProfile, updateTaxProfile } from "store/actions/taxProfiles";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { useTaxProfile } from "store/hooks/taxProfiles";
import { BaseStatus } from "store/reducers/standardReducer";
import { stringifyAddress } from "utils/helpers";
import { useWindowSize } from "utils/hooks";
import ConfirmBirthplace from "./ConfirmBirthplace";
import ConfirmBusinessName from "./ConfirmBusinessName";
import ConfirmDateOfBirth from "./ConfirmDateOfBirth";
import ConfirmGovernmentPublicEntity from "./ConfirmGovernmentPublicEntity";
import ConfirmPrimaryAddress from "./ConfirmPrimaryAddress";
import ConfirmRecipientName from "./ConfirmRecipientName";
import ConfirmRecipientType from "./ConfirmRecipientType";
import ConfirmVATNumber from "./ConfirmVATNumber";
import EnterBusinessNumber from "./EnterBusinessNumber";
import EnterTINNumber from "./EnterTINNumber";
import EnterVATNumber from "./EnterVATNumber";
import GDPRMessage from "./GDPRMessage";
import GreetingMessage from "./GreetingMessage";
import OutOfScope from "./OutOfScope";
import Summary from "./Summary";
import TaxProfileComplete from "./TaxProfileComplete";
import UpdatePrimaryAddress from "./UpdatePrimaryAddress";
import { ActionType, useIntl, useStepperEffect } from "utils/context";
import Helmet from "react-helmet";

export enum WizardPages {
  GREETING_MESSAGE = "GreetingMessage",
  CONFIRM_GOVERNMENT_PUBLIC_ENTITY = "ConfirmGovernmentPublicEntity",
  GDPR_MESSAGE = "GDPRMessage",
  CONFIRM_BIRTHPLACE = "ConfirmBirthplace",
  CONFIRM_DATE_OF_BIRTH = "ConfirmDateOfBirth",
  CONFIRM_BUSINESS_NAME = "ConfirmBusinessName",
  CONFIRM_RECIPIENT_NAME = "ConfirmRecipientName",
  CONFIRM_RECIPIENT_TYPE = "ConfirmRecipientType",
  CONFIRM_PRIMARY_ADDRESS = "ConfirmPrimaryAddress",
  UPDATE_PRIMARY_ADDRESS = "UpdatePrimaryAddress",
  CONFIRM_VAT_NUMBER = "ConfirmVATNumber",
  ENTER_VAT_NUMBER = "EnterVATNumber",
  ENTER_TIN_NUMBER = "EnterTINNumber",
  ENTER_BUSINESS_NUMBER = "EnterBusinessNumber",
  SUMMARY = "Summary",
  OUT_OF_SCOPE = "OutOfScope",
  TAX_PROFILE_COMPLETE = "TaxProfileComplete",
}

const END_PAGES = [WizardPages.OUT_OF_SCOPE, WizardPages.SUMMARY, WizardPages.TAX_PROFILE_COMPLETE];

export enum WIZARD_SECTIONS {
  TAX_PROFILE = "taxProfileSectionPages",
  TAX_IDENTIFICATION = "taxIdentificationSectionPages",
  DETAILS = "detailsSectionPages",
}

export type SummarySectionsType = {
  [key in RecipientType]: {
    [key in WIZARD_SECTIONS]: WizardPages[];
  };
};

const SECTION_PAGES: SummarySectionsType = {
  [RecipientType.INDIVIDUAL]: {
    [WIZARD_SECTIONS.TAX_PROFILE]: [
      WizardPages.CONFIRM_RECIPIENT_NAME,
      WizardPages.CONFIRM_PRIMARY_ADDRESS,
      WizardPages.UPDATE_PRIMARY_ADDRESS,
    ],
    [WIZARD_SECTIONS.TAX_IDENTIFICATION]: [
      WizardPages.CONFIRM_VAT_NUMBER,
      WizardPages.ENTER_VAT_NUMBER,
      WizardPages.ENTER_TIN_NUMBER,
      WizardPages.CONFIRM_BIRTHPLACE,
    ],
    [WIZARD_SECTIONS.DETAILS]: [WizardPages.CONFIRM_BIRTHPLACE, WizardPages.CONFIRM_DATE_OF_BIRTH],
  },
  [RecipientType.BUSINESS]: {
    [WIZARD_SECTIONS.TAX_PROFILE]: [
      WizardPages.CONFIRM_BUSINESS_NAME,
      WizardPages.CONFIRM_PRIMARY_ADDRESS,
      WizardPages.UPDATE_PRIMARY_ADDRESS,
    ],
    [WIZARD_SECTIONS.TAX_IDENTIFICATION]: [
      WizardPages.CONFIRM_VAT_NUMBER,
      WizardPages.ENTER_VAT_NUMBER,
      WizardPages.ENTER_TIN_NUMBER,
      WizardPages.ENTER_BUSINESS_NUMBER,
    ],
    [WIZARD_SECTIONS.DETAILS]: [],
  },
};

export interface TaxProfileWizard {
  taxProfileId?: string;
  // ConfirmGovernmentPublicEntity
  confirmGovernmentEntity?: string;
  // ConfirmBirthplace
  birthplace?: {
    country?: string; // recipient?.birthplace?.country
    city?: string; // recipient?.birthplace?.city
  };
  // ConfirmDateOfBirth
  dob?: string; // recipient?.dob
  // ConfirmBusinessName
  confirmBusinessName?: string;
  payoutRecipientBusinessName?: string; // merchant?.name
  businessName?: string;
  // ConfirmRecipientName
  confirmName?: string;
  payoutRecipientFirstName?: string; // recipient?.firstName
  payoutRecipientLastName?: string; // recipient?.lastName
  recipientFirstName?: string;
  recipientLastName?: string;
  // ConfirmRecipientType
  payoutRecipientType?: RecipientType; // recipient?.type
  confirmedRecipientType?: RecipientType;
  // ConfirmPrimaryAddress
  stringifiedPayoutAddress?: string; // stringifyAddress({ ...recipient?.address, country: formatCountry(recipient?.address.country), region: getRegionLabel(recipient?.address.region, recipient?.address.country) })
  payoutRecipientAddress?: {
    street1?: string; // recipient?.address?.street1
    street2?: string; // recipient?.address?.street2
    city?: string; // recipient?.address?.city
    country?: string; // recipient?.address?.country
    region?: string; // recipient?.address?.region
    postalCode?: string; // recipient?.address?.postalCode
  };
  confirmAddress?: string;
  // UpdatePrimaryAddress
  address?: {
    street1?: string;
    street2?: string;
    city?: string;
    country?: string;
    region?: string;
    postalCode?: string;
  };
  // ConfirmVATNumber
  confirmVATNumber?: string;
  // EnterVATNumber
  vatCountry?: string;
  vatNumber?: string;
  // EnterBusinessNumber
  businessCountry?: string;
  businessNumber?: string;
  // EnterTINNumber
  tinNumbers?: {
    tinCountry: string;
    tinNumber: string;
    tinType?: string | null;
    otherTinDescription?: string;
  }[]; // [{ country: "", tinNumber: "" }]

  // followedPath, includes current page
  followedPath?: WizardPages[];
}

export interface WizardPageProps {
  onContinue: (taxProfileWizard: Partial<TaxProfileWizard>) => void;
  taxProfileWizard: TaxProfileWizard;
  hideBackButton?: (hide: boolean) => void;
}

export interface TaxProfileWizardProps {
  path: WizardPages[];
  updatePath: (taxProfileWizard: TaxProfileWizard) => void;
}

export default function Wizard({ path, updatePath }: TaxProfileWizardProps) {
  const { taxProfileId = "" } = useParams<{ taxProfileId?: string }>();
  const { formatMessage } = useIntl();
  const { data: taxProfile, status: taxProfileStatus } = useTaxProfile(taxProfileId || "");
  // only for new tax profiles
  const [taxProfileClassification, setTaxProfileClassification] = useState<TaxProfileClassification | null>(null);
  const merchant = useMerchant();
  const recipient = useRecipient();
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const [pathIndex, setPathIndex] = useState(0);
  const [editingSection, setEditingSection] = useState<WizardPages[] | null>(null);
  const [wizardStatus, setWizardStatus] = useState(
    !taxProfileId ? OnboardingStatus.NOT_STARTED : OnboardingStatus.IN_PROGRESS,
  );
  const [taxProfileWizard, setTaxProfileWizard] = useState<TaxProfileWizard>({});
  const [isFirstPageInitialized, setIsFirstPageInitialized] = useState(false);
  const [isWizardInitialized, setIsWizardInitialized] = useState(false);
  const [activePage, setActivePage] = useState<WizardPages>(WizardPages.GREETING_MESSAGE);
  const [forceHideBackButton, setForceHideBackButton] = useState(false);

  useStepperEffect(ActionType.TAX_EU, path?.length ? (pathIndex + 1) / path.length : 0);

  const stringifiedPayoutAddress = stringifyAddress({
    ...recipient?.address,
    country: formatCountry(recipient?.address.country),
    region: getRegionLabel(recipient?.address.region, recipient?.address.country),
  }).trim();

  function getCurrentPathPage() {
    return path ? path[pathIndex] : WizardPages.GREETING_MESSAGE;
  }

  function getNextIndex(pathIndex: number) {
    if (!path) return pathIndex;

    const currentPage = getCurrentPathPage();

    if (editingSection && !editingSection.includes(currentPage)) {
      setEditingSection(null);
    }

    if (editingSection && editingSection.includes(currentPage)) {
      const currentIndexSection = editingSection.indexOf(currentPage);
      const remainingSection = editingSection.slice(currentIndexSection + 1);
      const nextSectionPage = path.find((page: WizardPages) => remainingSection.includes(page));
      if (!nextSectionPage) {
        setEditingSection(null);

        return path.indexOf(WizardPages.SUMMARY);
      }

      return path.indexOf(nextSectionPage);
    }

    return !END_PAGES.includes(currentPage) && pathIndex < path.length - 1 ? pathIndex + 1 : pathIndex;
  }

  function getPreviousIndex(pathIndex: number) {
    return pathIndex > 0 ? pathIndex - 1 : pathIndex;
  }

  useEffect(() => {
    setActivePage(() => getCurrentPathPage());
  }, [pathIndex]);

  // to determine the fist page to edit when editing a section
  useEffect(() => {
    if (path && editingSection) {
      const firstSectionPage = path.find((page: WizardPages) => editingSection.includes(page));
      if (!firstSectionPage) {
        setEditingSection(null);

        return;
      }
      const firstIndexSection = path.indexOf(firstSectionPage);
      if (firstIndexSection < 0) {
        setEditingSection(null);

        return;
      }
      setPathIndex(firstIndexSection);
    }
  }, [editingSection]);

  function isOutOfScope() {
    const classification = taxProfile ? taxProfile?.classification : taxProfileClassification;

    return (
      classification &&
      [TaxProfileClassification.IN_SCOPE_EXCLUDED, TaxProfileClassification.OUT_OF_SCOPE].includes(classification)
    );
  }

  // rebuild path according to taxProfileWizard state
  useEffect(() => {
    if (isWizardInitialized) {
      updatePath(taxProfileWizard);
    }
  }, [taxProfileWizard, recipient, merchant]);

  useEffect(() => {
    if (isWizardInitialized) {
      onMoveNext();
    }
  }, [path]);

  useEffect(() => {
    if (taxProfileId) {
      if (taxProfileStatus === BaseStatus.ERROR && !taxProfile) {
        notifyError("Tax Profile Not Found");
        history.push(PATHS.HOME);
      }
    }
  }, [taxProfileId, taxProfileStatus, history.location.pathname]);

  useEffect(() => {
    if (isWizardInitialized) return;
    if (!taxProfileId) {
      initializeWizard();

      return;
    }
    if (taxProfileId && taxProfileStatus === BaseStatus.LOADED && taxProfile) {
      initializeWizard(taxProfile);
    }
  }, [taxProfileId, taxProfile, taxProfileStatus]);

  const initializeWizard = useCallback(
    (taxProfile?: TaxProfile) => {
      if (isWizardInitialized) return;

      const getConfirmValue = (taxProfileValue: string | null | undefined, prefilledValue: string = "") => {
        if (!taxProfileValue) return "";

        return taxProfileValue === prefilledValue ? "yes" : "no";
      };

      const taxProfileAddress = !taxProfile
        ? ""
        : stringifyAddress({
            street1: taxProfile.data.primaryStreet1,
            street2: taxProfile.data.primaryStreet2,
            city: taxProfile.data.primaryCity,
            postalCode: taxProfile.data.primaryPostalCode,
            country: formatCountry(taxProfile.primaryCountry),
            region: getRegionLabel(taxProfile.data.primaryRegionCode, taxProfile.primaryCountry),
          });

      setTaxProfileWizard(() => {
        return {
          taxProfileId: taxProfile?.id,
          confirmGovernmentEntity: !taxProfileId ? "" : taxProfile ? taxProfile.data.isGovernmentOrPublicEntity : "",
          birthplace: {
            country: taxProfile?.data.birthCountry ?? recipient?.birthplace?.country,
            city: taxProfile?.data.birthCity ?? recipient?.birthplace?.city,
          },
          dob: taxProfile?.data.dob ?? recipient?.dob,
          confirmBusinessName: getConfirmValue(taxProfile?.data.businessName, recipient?.name),
          payoutRecipientBusinessName: recipient?.name,
          businessName: taxProfile?.data.businessName ?? recipient?.name ?? "",
          confirmName: getConfirmValue(
            taxProfile && taxProfile.data.firstName && taxProfile.data.lastName
              ? `${taxProfile?.data.firstName} ${taxProfile?.data.lastName}`
              : null,
            `${recipient?.firstName} ${recipient?.lastName}`,
          ),
          payoutRecipientFirstName: recipient?.firstName,
          payoutRecipientLastName: recipient?.lastName,
          recipientFirstName: taxProfile?.data.firstName ?? recipient?.firstName,
          recipientLastName: taxProfile?.data.lastName ?? recipient?.lastName,
          payoutRecipientType: recipient?.type,
          confirmedRecipientType: taxProfile?.recipientType ?? recipient?.type,
          stringifiedPayoutAddress: stringifiedPayoutAddress,
          payoutRecipientAddress: {
            street1: recipient?.address.street1,
            street2: recipient?.address.street2,
            city: recipient?.address.city,
            country: recipient?.address.country,
            region: recipient?.address?.region,
            postalCode: recipient?.address?.postalCode,
          },
          confirmAddress:
            !stringifiedPayoutAddress || stringifiedPayoutAddress === "-"
              ? "no"
              : getConfirmValue(
                  taxProfile && taxProfile.primaryCountry ? taxProfileAddress : null,
                  stringifiedPayoutAddress,
                ),
          address: {
            street1: taxProfile?.data.primaryStreet1 ?? recipient?.address.street1,
            street2: taxProfile?.data.primaryStreet2 ?? recipient?.address.street2,
            city: taxProfile?.data.primaryCity ?? recipient?.address.city,
            country: taxProfile?.primaryCountry ?? recipient?.address.country,
            region: taxProfile?.data.primaryRegionCode ?? recipient?.address?.region,
            postalCode: taxProfile?.data.primaryPostalCode ?? recipient?.address?.postalCode,
          },
          confirmVATNumber: taxProfile?.data.vatNumber ? "yes" : "",
          vatCountry: taxProfile?.vatCountry ?? "",
          vatNumber: taxProfile?.data.vatNumber ?? "",
          businessCountry: taxProfile?.data.PECountry ?? "",
          businessNumber: taxProfile?.data.businessNumber ?? "",
          tinNumbers: taxProfile?.tins && taxProfile.tins.length > 0 ? [...taxProfile.tins] : [],
          followedPath: taxProfile?.data.widgetSteps ?? [WizardPages.GREETING_MESSAGE],
        } as TaxProfileWizard;
      });
      setIsWizardInitialized(true);
    },
    [assignDeep, recipient, merchant, stringifiedPayoutAddress],
  );

  function mapUpdateTaxProfile(taxProfileWizard: TaxProfileWizard): RecursivePartial<TaxProfile> | null {
    switch (activePage) {
      case WizardPages.CONFIRM_GOVERNMENT_PUBLIC_ENTITY:
        return {
          id: taxProfileWizard.taxProfileId,
          recipientId: recipient?.id,
          status: TaxStatus.INCOMPLETE,
          data: {
            isGovernmentOrPublicEntity: taxProfileWizard.confirmGovernmentEntity === "yes",
            widgetSteps: [activePage],
          },
        };
      case WizardPages.GDPR_MESSAGE:
        return null;
      case WizardPages.CONFIRM_BIRTHPLACE:
        return {
          id: taxProfileWizard.taxProfileId,
          recipientId: recipient?.id,
          status: TaxStatus.INCOMPLETE,
          data: {
            birthCity: taxProfileWizard.birthplace?.city ?? null,
            birthCountry: taxProfileWizard.birthplace?.country as CountryCode,
            widgetSteps: [activePage],
          },
        };
      case WizardPages.CONFIRM_DATE_OF_BIRTH:
        return {
          id: taxProfileWizard.taxProfileId,
          recipientId: recipient?.id,
          status: TaxStatus.INCOMPLETE,
          data: {
            dob: taxProfileWizard.dob ?? "",
            widgetSteps: [activePage],
          },
        };
      case WizardPages.CONFIRM_BUSINESS_NAME:
        return {
          id: taxProfileWizard.taxProfileId,
          recipientId: recipient?.id,
          status: TaxStatus.INCOMPLETE,
          data: {
            businessName: taxProfileWizard.businessName ?? null,
            widgetSteps: [activePage],
          },
        };
      case WizardPages.CONFIRM_RECIPIENT_NAME:
        return {
          id: taxProfileWizard.taxProfileId,
          recipientId: recipient?.id,
          status: TaxStatus.INCOMPLETE,
          data: {
            firstName: taxProfileWizard.recipientFirstName ?? null,
            lastName: taxProfileWizard.recipientLastName ?? null,
            widgetSteps: [activePage],
          },
        };
      case WizardPages.CONFIRM_RECIPIENT_TYPE:
        return {
          id: taxProfileWizard.taxProfileId,
          recipientId: recipient?.id,
          status: TaxStatus.INCOMPLETE,
          recipientType: taxProfileWizard.confirmedRecipientType,
          data: {
            widgetSteps: [activePage],
          },
        };
      case WizardPages.CONFIRM_PRIMARY_ADDRESS:
        return taxProfileWizard.confirmAddress === "yes"
          ? {
              id: taxProfileWizard.taxProfileId,
              recipientId: recipient?.id,
              status: TaxStatus.INCOMPLETE,
              primaryCountry: taxProfileWizard.address?.country as CountryCode,
              data: {
                primaryStreet1: taxProfileWizard.address?.street1 ?? null,
                primaryStreet2: taxProfileWizard.address?.street2 ?? null,
                primaryCity: taxProfileWizard.address?.city ?? null,
                primaryPostalCode: taxProfileWizard.address?.postalCode ?? null,
                primaryRegionCode: taxProfileWizard.address?.region ?? null,
                widgetSteps: [activePage],
              },
            }
          : null;
      case WizardPages.UPDATE_PRIMARY_ADDRESS:
        return {
          id: taxProfileWizard.taxProfileId,
          recipientId: recipient?.id,
          status: TaxStatus.INCOMPLETE,
          primaryCountry: taxProfileWizard.address?.country as CountryCode,
          data: {
            primaryStreet1: taxProfileWizard.address?.street1 ?? null,
            primaryStreet2: taxProfileWizard.address?.street2 ?? null,
            primaryCity: taxProfileWizard.address?.city ?? null,
            primaryPostalCode: taxProfileWizard.address?.postalCode ?? null,
            primaryRegionCode: taxProfileWizard.address?.region ?? null,
            widgetSteps: [activePage],
          },
        };
      case WizardPages.CONFIRM_VAT_NUMBER:
        return taxProfileWizard.confirmVATNumber === "no"
          ? {
              id: taxProfileWizard.taxProfileId,
              recipientId: recipient?.id,
              status: TaxStatus.INCOMPLETE,
              vatCountry: null,
              data: {
                vatNumber: null,
                widgetSteps: [activePage],
              },
            }
          : null;
      case WizardPages.ENTER_VAT_NUMBER:
        return {
          id: taxProfileWizard.taxProfileId,
          recipientId: recipient?.id,
          status: TaxStatus.INCOMPLETE,
          vatCountry: taxProfileWizard.vatCountry ? (taxProfileWizard.vatCountry as CountryCode) : null,
          data: {
            vatNumber: taxProfileWizard.vatNumber ?? null,
            widgetSteps: [activePage],
          },
        };
      case WizardPages.ENTER_BUSINESS_NUMBER:
        return {
          id: taxProfileWizard.taxProfileId,
          recipientId: recipient?.id,
          status: TaxStatus.INCOMPLETE,
          data: {
            businessNumber: taxProfileWizard.businessNumber ?? null,
            PECountry: taxProfileWizard.businessCountry as CountryCode,
            widgetSteps: [activePage],
          },
        };
      case WizardPages.ENTER_TIN_NUMBER:
        return taxProfileWizard.tinNumbers && taxProfileWizard.tinNumbers?.length > 0
          ? {
              id: taxProfileWizard.taxProfileId,
              recipientId: recipient?.id,
              status: TaxStatus.INCOMPLETE,
              data: {
                widgetSteps: [activePage],
              },
              tins: [...taxProfileWizard.tinNumbers] as TaxProfileTinData[],
            }
          : {
              id: taxProfileWizard.taxProfileId,
              recipientId: recipient?.id,
              status: TaxStatus.INCOMPLETE,
              data: {
                widgetSteps: [activePage],
              },
              tins: [],
            };
      default:
        return null;
    }
  }

  async function onUpdateTaxProfile(updatedTaxProfileWizard: TaxProfileWizard) {
    const taxProfile = mapUpdateTaxProfile(updatedTaxProfileWizard);
    if (taxProfile) {
      const updatedTaxProfile: TaxProfile = await updateTaxProfile(
        taxProfileWizard.taxProfileId ?? taxProfileId,
        taxProfile,
      );
      if (updatedTaxProfile) {
        setTaxProfileClassification(updatedTaxProfile.classification);
      }
    }
  }

  function goBack() {
    if (activePage === WizardPages.OUT_OF_SCOPE) {
      setActivePage(getCurrentPathPage());

      return;
    }
    setPathIndex((pathIndex) => getPreviousIndex(pathIndex));
  }

  async function onStartWizard() {
    if (wizardStatus === OnboardingStatus.NOT_STARTED) {
      const taxProfile = await createTaxProfile({
        recipientId: recipient?.id ?? "",
        data: {
          // relevant activities is necessary to send otherwise it's being classified as out of scope
          relevantActivities: !!merchant?.payment?.categories
            ? (Object.keys(merchant?.payment?.categories).filter(
                (c) => merchant?.payment?.categories?.[c],
              ) as PaymentCategory[])
            : [],
        },
      });
      setTaxProfileWizard((taxProfileWizard) => {
        return {
          ...taxProfileWizard,
          taxProfileId: taxProfile.id,
        };
      });

      setWizardStatus(OnboardingStatus.IN_PROGRESS);
    } else {
      onMoveNext();
    }
  }

  async function onContinue(partialTaxProfileWizard: Partial<TaxProfileWizard>) {
    const updatedTaxProfileWizard = assignDeep({}, taxProfileWizard, partialTaxProfileWizard);
    await onUpdateTaxProfile(updatedTaxProfileWizard);
    setTaxProfileWizard(updatedTaxProfileWizard);
  }

  function onMoveNext() {
    if (path) {
      if (isOutOfScope()) {
        setActivePage(WizardPages.OUT_OF_SCOPE);

        return;
      }
      if (!isFirstPageInitialized) {
        const index = !!taxProfile?.data.widgetSteps?.length
          ? path.indexOf(taxProfile?.data.widgetSteps[0] as WizardPages)
          : 0;
        setPathIndex(index >= 0 && index < path.length ? index : 0);
        setIsFirstPageInitialized(true);

        return;
      }
      setPathIndex((pathIndex) => getNextIndex(pathIndex));
    }
  }

  function onSummaryEdit(section: WIZARD_SECTIONS) {
    // at this point confirmedRecipientType will always have a value
    if (taxProfileWizard.confirmedRecipientType) {
      setEditingSection([...SECTION_PAGES[taxProfileWizard.confirmedRecipientType][section]]);
    }
  }

  async function onSubmitTaxProfile(name: string) {
    const exitWizard = activePage === WizardPages.OUT_OF_SCOPE;
    if (name) {
      await signAndSubmitTaxProfile(taxProfileWizard.taxProfileId ?? taxProfileId, name);
    }
    if (!!exitWizard) {
      history.push(PATHS.HOME);

      return;
    }
    setActivePage(WizardPages.TAX_PROFILE_COMPLETE);
    setWizardStatus(OnboardingStatus.DONE);
  }

  async function onSaveAndQuit(save: boolean) {
    if (save) {
      await updateTaxProfile(taxProfileWizard.taxProfileId ?? taxProfileId, {
        id: taxProfileWizard.taxProfileId,
        recipientId: recipient?.id,
        status: TaxStatus.INCOMPLETE,
        data: {
          widgetSteps: [activePage],
        },
      });
    }
    history.push(PATHS.HOME);
  }

  return (
    <>
      <Helmet>
        <title>{formatMessage({ id: "containers.taxProfile.wizard.headerTitleWidget" })}</title>
      </Helmet>

      <OnboardingWrapper
        header={
          <OnboardingHeader
            status={wizardStatus}
            onSaveAndQuit={onSaveAndQuit}
            showBackButton={
              isMobile &&
              wizardStatus === OnboardingStatus.IN_PROGRESS &&
              activePage !== WizardPages.GREETING_MESSAGE &&
              !editingSection
            }
            onBackClick={goBack}
          />
        }
        hideBackButton={
          forceHideBackButton ||
          isMobile ||
          wizardStatus !== OnboardingStatus.IN_PROGRESS ||
          activePage === WizardPages.GREETING_MESSAGE ||
          !!editingSection
        }
        onBackClick={goBack}
      >
        <Tabs
          activeKey={activePage}
          // tabBarStyle={{ display: "none" }}
          renderTabBar={() => <></>}
          animated
          items={[
            {
              key: WizardPages.GREETING_MESSAGE,
              label: "Do you need a Tax Profile?",
              children: <GreetingMessage onStartWizard={onStartWizard} />,
            },
            {
              key: WizardPages.CONFIRM_GOVERNMENT_PUBLIC_ENTITY,
              label: "Confirm Government or Public entity",
              children: <ConfirmGovernmentPublicEntity taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.GDPR_MESSAGE,
              label: "Tax Profile Wizard",
              children: <GDPRMessage onContinue={onMoveNext} />,
            },
            {
              key: WizardPages.CONFIRM_BIRTHPLACE,
              label: "Confirm Birthplace",
              children: <ConfirmBirthplace taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.CONFIRM_DATE_OF_BIRTH,
              label: "Confirm Date Of Birth",
              children: <ConfirmDateOfBirth taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.CONFIRM_BUSINESS_NAME,
              label: "Confirm Business Name",
              children: <ConfirmBusinessName taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.CONFIRM_RECIPIENT_NAME,
              label: "Confirm Name",
              children: <ConfirmRecipientName taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.CONFIRM_RECIPIENT_TYPE,
              label: "Confirm Recipient Type",
              children: (
                <ConfirmRecipientType
                  taxProfileWizard={taxProfileWizard}
                  onContinue={onContinue}
                  taxProfile={taxProfile}
                />
              ),
            },
            {
              key: WizardPages.CONFIRM_PRIMARY_ADDRESS,
              label: "Confirm Primary Address",
              children: <ConfirmPrimaryAddress taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.UPDATE_PRIMARY_ADDRESS,
              label: "Update Primary Address",
              children: <UpdatePrimaryAddress taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.CONFIRM_VAT_NUMBER,
              label: "Confirm VAT Number",
              children: <ConfirmVATNumber taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.ENTER_VAT_NUMBER,
              label: "Enter VAT Number",
              children: <EnterVATNumber taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.ENTER_BUSINESS_NUMBER,
              label: "Enter Business Number",
              children: <EnterBusinessNumber taxProfileWizard={taxProfileWizard} onContinue={onContinue} />,
            },
            {
              key: WizardPages.ENTER_TIN_NUMBER,
              label: "Enter TIN Number",
              children: (
                <EnterTINNumber
                  taxProfileWizard={taxProfileWizard}
                  onContinue={onContinue}
                  hideBackButton={(hide: boolean) => {
                    setForceHideBackButton(hide);
                  }}
                />
              ),
            },
            {
              key: WizardPages.SUMMARY,
              label: "Summary",
              children: (
                <Summary
                  taxProfileWizard={taxProfileWizard}
                  onSubmitTaxProfile={onSubmitTaxProfile}
                  onSummaryEdit={onSummaryEdit}
                />
              ),
            },
            {
              key: WizardPages.OUT_OF_SCOPE,
              label: "No Further information required",
              children: <OutOfScope taxProfileWizard={taxProfileWizard} onSubmitTaxProfile={onSubmitTaxProfile} />,
            },
            {
              key: WizardPages.TAX_PROFILE_COMPLETE,
              label: "Tax Profile Complete",
              children: <TaxProfileComplete />,
            },
          ]}
        />
      </OnboardingWrapper>
    </>
  );
}
