import { FTINType, RecipientType } from "@trolley/common-frontend";
import React, { useMemo } from "react";

import { useIntl } from "utils/context";
import Select, { SelectOption, SelectProps } from "./Select";
import { filterFtinsByRecipientType, getFilteredFtinsByCountry } from "utils/helpers/ftins";

interface Props extends Omit<SelectProps, "options"> {
  country: string | undefined | null;
  addOtherOption?: boolean;
  showEmptyOption?: boolean;
  recipientType: RecipientType;
}

export default function SelectFTINType(props: Props) {
  const { formatMessage } = useIntl();
  const { country, addOtherOption, showEmptyOption = true, recipientType, ...rest } = props;
  const options = useMemo(() => {
    const otherLabel = formatMessage({ id: "components.formTinTypes.other" });
    const fTinsByCountry: Record<string, FTINType> = getFilteredFtinsByCountry();
    const otherOption = { label: otherLabel, value: "other", "data-search": otherLabel, "aria-label": otherLabel };
    if (!country) {
      return [];
    }

    const fTins: FTINType = fTinsByCountry[country]
      ? filterFtinsByRecipientType(fTinsByCountry[country], recipientType)
      : {};

    return [
      ...Object.entries(fTins).map(
        ([code, fTinData]) =>
          ({
            label: fTinData?.localName ?? fTinData?.name ?? "",
            value: code,
            "aria-label": fTinData?.name ?? "",
          } as SelectOption),
      ),
      ...(!!addOtherOption ? [otherOption] : []),
    ];
  }, [country]);

  return (
    <Select
      {...rest}
      placeholder={formatMessage({ id: "components.formTinTypes.select" })}
      options={options}
      allowClear={showEmptyOption}
      aria-label={formatMessage({ id: "components.formTinTypes.select" })}
    />
  );
}
