import React, { useCallback, useEffect, useState } from "react";
import config from "config";
import { BankFieldType, CountryCode, RecipientType, Rules } from "@trolley/common-frontend";
import { Form2 as Form } from "components";
import debounce from "lodash.debounce";
import { FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP, mapBankAccountQuery } from "pages/PayoutMethod2/BankTransfer";
import AccountVerificationResultAlert from "pages/PayoutMethod2/BankTransfer/AccountVerificationResultAlert";
import { AccountVerificationQuery } from "store/actions/bankInfo";
import { useAccountVerification } from "store/hooks/bankInfo";
import { useRecipient } from "store/hooks/recipient";
import { BaseStatus } from "store/reducers/standardReducer";
import { useMerchant } from "store/hooks/merchant";

interface Props {
  countryRule: BankFieldType[];
}

export default function AccountVerificationFormField(props: Props) {
  const { countryRule } = props;
  const merchant = useMerchant();
  const recipient = useRecipient();
  const [accountQuery, setAccountQuery] = useState<AccountVerificationQuery | undefined>();
  const { data: accountVerifResult, status: accountVerifStatus } = useAccountVerification(
    accountQuery,
    recipient?.type as RecipientType,
  );
  const [requiresAccountVerification, setRequiresAccountVerification] = useState(false);
  const form = Form.useFormInstance();
  const currency = Form.useWatch("currency", form);
  const iban = Form.useWatch("iban", form);
  const branchId = Form.useWatch("branchId", form);
  const accountNum = Form.useWatch("accountNum", form);
  const accountHolderName = Form.useWatch("accountHolderName", form);
  const accountHolderFirstName = Form.useWatch("accountHolderFirstName", form);
  const accountHolderLastName = Form.useWatch("accountHolderLastName", form);

  useEffect(() => {
    const requiresVerif =
      !!merchant?.features?.accountVerificationUk &&
      recipient?.address.country === CountryCode.GB &&
      config.ENV !== "production";
    if (requiresVerif !== requiresAccountVerification) {
      setRequiresAccountVerification(requiresVerif);
    }
  }, [merchant, recipient]);

  useEffect(() => {
    form.setFieldValue("requiresAccountVerification", requiresAccountVerification);
  }, [requiresAccountVerification]);

  useEffect(() => {
    onValuesChange();
  }, [currency, iban, branchId, accountNum, accountHolderName, accountHolderFirstName, accountHolderLastName]);

  useEffect(() => {
    if (accountVerifResult) {
      form.setFieldValue("accountVerif", accountVerifResult);
      form.validateFields(["accountVerif"]).catch(() => {
        //
      });
    }
  }, [accountVerifResult]);

  useEffect(() => {
    form.setFieldValue("accountVerifStatus", accountVerifStatus);
  }, [accountVerifStatus]);

  function replaceName(accountHolderName: string) {
    if (recipient?.type === RecipientType.INDIVIDUAL) {
      const accountHolderNameTokens = accountHolderName.split(" ");
      const accountHolderFirstName = accountHolderNameTokens?.[0];
      const accountHolderLastName =
        accountHolderNameTokens && accountHolderNameTokens.length > 1
          ? accountHolderNameTokens.slice(1).join(" ")
          : null;
      if (accountHolderFirstName && accountHolderLastName) {
        form.setFieldValue("accountHolderFirstName", accountHolderFirstName);
        form.setFieldValue("accountHolderLastName", accountHolderLastName);
      }
    }

    if (recipient?.type === RecipientType.BUSINESS) {
      form.setFieldValue("accountHolderName", accountHolderName);
    }
  }

  function onValuesChange() {
    form.resetFields(["accountVerif"]);
    setAccountQuery(undefined);
    debounceSetQuery(countryRule);
  }

  const debounceSetQuery = useCallback(
    debounce((countryRule: Rules) => {
      const fieldsValue = form.getFieldsValue();
      const errors = form.getFieldsError(FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP);
      const validValues = [
        ...countryRule,
        ...(recipient?.type === RecipientType.INDIVIDUAL
          ? ["accountHolderFirstName", "accountHolderLastName"]
          : ["accountHolderName"]),
      ].every((rule) => {
        if (!FIELDS_REQUIRING_BANK_ACCOUNT_LOOKUP.includes(rule)) {
          return true;
        }

        if (!fieldsValue[rule]) {
          return false;
        }

        return !fieldsValue[rule]?.includes("*");
      });

      const hasErrors = Object.values(errors).some((item) => item.errors.length > 0); // Errors on fields that matters to the bank lookup
      if (!hasErrors && validValues && recipient?.id) {
        const mappedQuery = mapBankAccountQuery(fieldsValue, recipient.id, CountryCode.GB);
        setAccountQuery(mappedQuery ?? undefined);
      } else {
        setAccountQuery(undefined);
      }
    }, 650),
    [form],
  );

  return (
    <>
      {requiresAccountVerification &&
        accountVerifStatus &&
        accountVerifStatus !== BaseStatus.ERROR &&
        accountVerifResult &&
        recipient && (
          <Form.Item
            name="accountVerif"
            shouldUpdate
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AccountVerificationResultAlert
              result={accountVerifResult}
              recipientType={recipient.type as RecipientType}
              replaceName={replaceName}
            />
          </Form.Item>
        )}
      <Form.Item name="accountVerifStatus" />
      <Form.Item name="requiresAccountVerification" />
    </>
  );
}
