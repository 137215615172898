import { CountryCode, getBankRuleProps, RecipientType } from "@trolley/common-frontend";
import React, { useEffect, useState } from "react";
import config from "config";
import { Form2 as Form } from "components";
import localeBankPayoutMethod from "locale/en/containers/bankPayoutMethod.json";
import { useRecipient } from "store/hooks/recipient";
import { useIntl } from "utils/context";
import { getHolderNameWarnings } from "./NameWarning";
import { useMerchant } from "store/hooks/merchant";

export default function InputAccountHolderName() {
  const merchant = useMerchant();
  const recipient = useRecipient();
  const { formatMessage } = useIntl();
  const accountHolderNameRules = getBankRuleProps("accountHolderName", undefined, {}, formatMessage);
  const [requiresAccountVerification, setRequiresAccountVerification] = useState(false);

  useEffect(() => {
    const requiresVerif =
      !!merchant?.features?.accountVerificationUk &&
      recipient?.address.country === CountryCode.GB &&
      config.ENV !== "production";
    if (requiresVerif !== requiresAccountVerification) {
      setRequiresAccountVerification(requiresVerif);
    }
  }, [merchant, recipient]);

  if (!recipient) {
    return null;
  }

  if (accountHolderNameRules && requiresAccountVerification && recipient?.type === RecipientType.INDIVIDUAL) {
    return (
      <>
        <Form.Item
          name="accountHolderFirstName"
          key="bank_field_accountHolderFirstName"
          label={formatMessage({
            id: "containers.bankPayoutMethod.accountHolderFirstName.title",
            defaultMessage: localeBankPayoutMethod.accountHolderFirstName.title,
          })}
          extra={formatMessage({
            id: "containers.bankPayoutMethod.correctFirstName",
            defaultMessage: localeBankPayoutMethod.correctFirstName,
          })}
          rules={[
            ...(accountHolderNameRules.rules || []),
            {
              warningOnly: true,
              async validator(rule, value) {
                if (value) {
                  const holderNameWarnings = getHolderNameWarnings(formatMessage, recipient, value);

                  if (holderNameWarnings.length) {
                    throw new Error(holderNameWarnings[0].warningText);
                  }
                }
              },
            },
          ]}
        >
          <Form.Input type="text" name="accountHolderFirstName" placeholder="eg. John" />
        </Form.Item>
        <Form.Item
          name="accountHolderLastName"
          key="bank_field_accountHolderLastName"
          label={formatMessage({
            id: "containers.bankPayoutMethod.accountHolderLastName.title",
            defaultMessage: localeBankPayoutMethod.accountHolderLastName.title,
          })}
          extra={formatMessage({
            id: "containers.bankPayoutMethod.correctLastName",
            defaultMessage: localeBankPayoutMethod.correctLastName,
          })}
          rules={[
            ...(accountHolderNameRules.rules || []),
            {
              warningOnly: true,
              async validator(rule, value) {
                if (value) {
                  const holderNameWarnings = getHolderNameWarnings(formatMessage, recipient, value);

                  if (holderNameWarnings.length) {
                    throw new Error(holderNameWarnings[0].warningText);
                  }
                }
              },
            },
          ]}
        >
          <Form.Input type="text" name="accountHolderLastName" placeholder="eg. Smith" />
        </Form.Item>
      </>
    );
  }

  return accountHolderNameRules ? (
    <Form.Item
      name="accountHolderName"
      key="bank_field_accountHolderName"
      label={formatMessage({
        id: "containers.bankPayoutMethod.accountHolderName.title",
        defaultMessage: localeBankPayoutMethod.accountHolderName.title,
      })}
      extra={formatMessage({
        id: "containers.bankPayoutMethod.correctName",
        defaultMessage: localeBankPayoutMethod.correctName,
      })}
      rules={[
        ...(accountHolderNameRules.rules || []),
        {
          warningOnly: true,
          async validator(rule, value) {
            if (value) {
              const holderNameWarnings = getHolderNameWarnings(formatMessage, recipient, value);

              if (holderNameWarnings.length) {
                throw new Error(holderNameWarnings[0].warningText);
              }
            }
          },
        },
      ]}
    >
      <Form.Input type="text" name="accountHolderName" placeholder="eg. John Smith" />
    </Form.Item>
  ) : null;
}
