import React, { useState } from "react";
import { BackButton, Button, Divider, GreyBox, Modal, Space, Typography } from "components";
import { AccountVerification } from "store/actions/bankInfo";
import { useIntl } from "utils/context";
import { RecipientType } from "@trolley/common-frontend";
import { AccVerifResultEnum } from "./AccountVerificationResultAlert";

interface Props {
  result?: AccountVerification;
  disabled?: boolean;
  submitCall?: () => void;
  recipientType?: RecipientType;
  replaceName?: (fullName: string) => void;
}

export default function AccountVerificationSubmitButton({
  result,
  disabled,
  submitCall,
  recipientType = RecipientType.INDIVIDUAL,
  replaceName,
}: Props) {
  const [modal, setModal] = useState<AccVerifResultEnum | null>(null);
  const { formatMessage } = useIntl();

  function getButtonLabel() {
    if (!result) return formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av100.button" });
    let label = "";
    switch (result?.reasonCode) {
      case AccVerifResultEnum.AV100:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av100.button" });
        break;
      case AccVerifResultEnum.AV201:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av201.button" });
        break;
      case AccVerifResultEnum.AV202:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av202.button" });
        break;
      case AccVerifResultEnum.AV203:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av203.button" });
        break;
      case AccVerifResultEnum.AV204:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av204.button" });
        break;
      case AccVerifResultEnum.AV205:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av205.button" });
        break;
      case AccVerifResultEnum.AV206:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.button" });
        break;
      case AccVerifResultEnum.AV300:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av300.button" });
        break;
      case AccVerifResultEnum.AV301:
        label = result.actualName
          ? formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av300.button" })
          : formatMessage({
              id: `containers.bankPayoutMethod.accountVerification.${recipientType}.closeMatch.button`,
            });
        break;
      case AccVerifResultEnum.AV302:
        label = result.actualName
          ? formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av300.button" })
          : formatMessage({
              id: `containers.bankPayoutMethod.accountVerification.${recipientType}.closeMatch.button`,
            });
        break;
      case AccVerifResultEnum.AV303:
        label = formatMessage({ id: `containers.bankPayoutMethod.accountVerification.${recipientType}.match.button` });
        break;
      case AccVerifResultEnum.AV304:
        label = formatMessage({ id: `containers.bankPayoutMethod.accountVerification.${recipientType}.match.button` });
        break;
      default:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.button" });
        break;
    }

    return label;
  }

  function submit() {
    if (submitCall) {
      submitCall();
    }
  }

  function onClick() {
    if (result?.reasonCode === AccVerifResultEnum.AV100) {
      submit();

      return;
    }

    setModal(result?.reasonCode as AccVerifResultEnum);
  }

  function getModalTitle() {
    if (!modal) return null;

    let modalTitle = "";
    switch (modal) {
      case AccVerifResultEnum.AV201:
        modalTitle = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av201.dialog.title" });
        break;
      case AccVerifResultEnum.AV202:
        modalTitle = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av202.dialog.title" });
        break;
      case AccVerifResultEnum.AV203:
        modalTitle = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av203.dialog.title" });
        break;
      case AccVerifResultEnum.AV204:
        modalTitle = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av204.dialog.title" });
        break;
      case AccVerifResultEnum.AV205:
        modalTitle = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av205.dialog.title" });
        break;
      case AccVerifResultEnum.AV206:
        modalTitle = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.dialog.title" });
        break;
      case AccVerifResultEnum.AV300:
        modalTitle = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.av300.${recipientType}.dialog.title`,
        });
        break;
      case AccVerifResultEnum.AV301:
        modalTitle = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av301.dialog.title" });
        break;
      case AccVerifResultEnum.AV302:
        modalTitle = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av301.dialog.title" });
        break;
      case AccVerifResultEnum.AV303:
        modalTitle = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.${recipientType}.match.dialog.title`,
        });
        break;
      case AccVerifResultEnum.AV304:
        modalTitle = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.${recipientType}.match.dialog.title`,
        });
        break;
      default:
        modalTitle = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.dialog.title" });
        break;
    }

    return modalTitle;
  }

  function getModalBodyAV20X() {
    return (
      <Typography.Text>
        {modal === AccVerifResultEnum.AV201 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av201.dialog.body",
          })}
        {modal === AccVerifResultEnum.AV202 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av202.dialog.body",
          })}
        {modal === AccVerifResultEnum.AV203 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av203.dialog.body",
          })}
        {modal === AccVerifResultEnum.AV204 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av204.dialog.body",
          })}
        {modal === AccVerifResultEnum.AV205 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av205.dialog.body",
          })}
        {modal === AccVerifResultEnum.AV206 &&
          formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av206.dialog.body",
          })}
      </Typography.Text>
    );
  }

  function getModalBodyAV300() {
    return (
      <>
        <Typography.Text>
          {formatMessage({
            id: `containers.bankPayoutMethod.accountVerification.av300.${recipientType}.dialog.body`,
          })}
        </Typography.Text>
        <Divider transparent />
        <GreyBox border={false}>
          <Typography.Text type="secondary">
            {formatMessage({ id: "containers.bankPayoutMethod.accountVerification.common.suggestedName" })}
          </Typography.Text>
          <Divider transparent size="small" />
          <Typography.Text>{result?.actualName}</Typography.Text>
        </GreyBox>
      </>
    );
  }

  function getModalBodyAV301() {
    return (
      <>
        <ul>
          <li>{formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av301.dialog.issue1" })}</li>
          <li>
            {formatMessage({
              id: `containers.bankPayoutMethod.accountVerification.av301.dialog.issue2.${recipientType}`,
            })}
          </li>
        </ul>
        <Divider transparent size="small" />
        <Typography.Text>
          {formatMessage({
            id: "containers.bankPayoutMethod.accountVerification.av301.dialog.body",
          })}
        </Typography.Text>
      </>
    );
  }

  function getModalBody() {
    if (!modal) return null;

    if (modal === AccVerifResultEnum.AV300) {
      return getModalBodyAV300();
    }

    if (modal && [AccVerifResultEnum.AV301, AccVerifResultEnum.AV302].includes(modal)) {
      return getModalBodyAV301();
    }

    if (modal && [AccVerifResultEnum.AV303, AccVerifResultEnum.AV304].includes(modal)) {
      return null;
    }

    return getModalBodyAV20X();
  }

  function getOnOkPrimaryButtonLabel() {
    let label = "";
    switch (modal) {
      case AccVerifResultEnum.AV201:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av201.dialog.okPrimaryButton" });
        break;
      case AccVerifResultEnum.AV202:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av202.dialog.okPrimaryButton" });
        break;
      case AccVerifResultEnum.AV203:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av203.dialog.okPrimaryButton" });
        break;
      case AccVerifResultEnum.AV204:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av204.dialog.okPrimaryButton" });
        break;
      case AccVerifResultEnum.AV205:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av205.dialog.okPrimaryButton" });
        break;
      case AccVerifResultEnum.AV206:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.dialog.okPrimaryButton" });
        break;
      case AccVerifResultEnum.AV300:
        label = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.av300.${recipientType}.dialog.okPrimaryButton`,
        });
        break;
      default:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.dialog.okPrimaryButton" });
        break;
    }

    return label;
  }

  function getOnOkSecondaryButtonLabel() {
    if (modal === AccVerifResultEnum.AV300) {
      return formatMessage({
        id: `containers.bankPayoutMethod.accountVerification.av300.${recipientType}.dialog.okSecondaryButton`,
      });
    }

    if (modal === AccVerifResultEnum.AV303 || modal === AccVerifResultEnum.AV304) {
      return formatMessage({
        id: `containers.bankPayoutMethod.accountVerification.${recipientType}.match.dialog.okSecondaryButton`,
      });
    }

    return "";
  }

  function getOnCancelButtonLabel() {
    let label = "";
    switch (modal) {
      case AccVerifResultEnum.AV201:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av201.dialog.cancelButton" });
        break;
      case AccVerifResultEnum.AV202:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av202.dialog.cancelButton" });
        break;
      case AccVerifResultEnum.AV203:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av203.dialog.cancelButton" });
        break;
      case AccVerifResultEnum.AV204:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av204.dialog.cancelButton" });
        break;
      case AccVerifResultEnum.AV205:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av205.dialog.cancelButton" });
        break;
      case AccVerifResultEnum.AV206:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.dialog.cancelButton" });
        break;
      case AccVerifResultEnum.AV300:
        label = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.av300.${recipientType}.dialog.cancelButton`,
        });
        break;
      case AccVerifResultEnum.AV303:
        label = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.${recipientType}.match.dialog.cancelButton`,
        });
        break;
      case AccVerifResultEnum.AV304:
        label = formatMessage({
          id: `containers.bankPayoutMethod.accountVerification.${recipientType}.match.dialog.cancelButton`,
        });
        break;
      default:
        label = formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av206.dialog.cancelButton" });
        break;
    }

    return label;
  }

  function onOkPrimaryModalButton() {
    if (modal === AccVerifResultEnum.AV300) {
      if (replaceName && result?.actualName) {
        replaceName(result.actualName);
        setModal(null);

        return;
      }
    }
    submit();
    setModal(null);
  }

  function onOkSecondaryModalButton() {
    if (modal && [AccVerifResultEnum.AV300, AccVerifResultEnum.AV303, AccVerifResultEnum.AV304].includes(modal)) {
      setModal(null);
      submit();
    }
  }

  function onCancelModalButton() {
    setModal(null);
  }

  function renderCancelButtonModal() {
    return (
      <Button onClick={onCancelModalButton} aria-label={getOnCancelButtonLabel()}>
        {getOnCancelButtonLabel()}
      </Button>
    );
  }

  function renderPrimaryButtonModal() {
    return (
      <Button type="primary" onClick={onOkPrimaryModalButton} aria-label={getOnOkPrimaryButtonLabel()}>
        {getOnOkPrimaryButtonLabel()}
      </Button>
    );
  }

  function getModalButtons() {
    if (!modal) return null;

    if (modal === AccVerifResultEnum.AV300) {
      return (
        <Space direction="column" align="start">
          {renderPrimaryButtonModal()}
          <Button onClick={onOkSecondaryModalButton} aria-label={getOnOkSecondaryButtonLabel()}>
            {getOnOkSecondaryButtonLabel()}
          </Button>
          {renderCancelButtonModal()}
        </Space>
      );
    }

    if ([AccVerifResultEnum.AV301, AccVerifResultEnum.AV302].includes(modal)) {
      return (
        <Space>
          <Button
            onClick={onOkPrimaryModalButton}
            aria-label={formatMessage({
              id: "containers.bankPayoutMethod.accountVerification.av301.dialog.okPrimaryButton",
            })}
          >
            {formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av301.dialog.okPrimaryButton" })}
          </Button>
          <Button
            type="primary"
            onClick={onCancelModalButton}
            aria-label={formatMessage({
              id: "containers.bankPayoutMethod.accountVerification.av301.dialog.cancelButton",
            })}
          >
            {formatMessage({ id: "containers.bankPayoutMethod.accountVerification.av301.dialog.cancelButton" })}
          </Button>
        </Space>
      );
    }

    if ([AccVerifResultEnum.AV303, AccVerifResultEnum.AV304].includes(modal)) {
      const isMatch = modal === AccVerifResultEnum.AV303 || modal === AccVerifResultEnum.AV304;

      return (
        <Space>
          {renderCancelButtonModal()}
          <Button onClick={onOkSecondaryModalButton} aria-label={getOnOkSecondaryButtonLabel()}>
            {getOnOkSecondaryButtonLabel()}
          </Button>
          <BackButton
            type="primary"
            title={formatMessage({
              id: `containers.bankPayoutMethod.accountVerification.${recipientType}.${
                isMatch ? "match" : "closeMatch"
              }.dialog.okPrimaryButton`,
            })}
          />
        </Space>
      );
    }

    return (
      <Space>
        {renderCancelButtonModal()}
        {renderPrimaryButtonModal()}
      </Space>
    );
  }

  return (
    <>
      <Button type="primary" onClick={onClick} aria-label={getButtonLabel()} disabled={!result || disabled}>
        {getButtonLabel()}
      </Button>
      <Modal open={!!modal} closable cancelButtonProps={{ style: { display: "none" } }} onCancel={() => setModal(null)}>
        {getModalTitle()}
        <Divider transparent />
        {getModalBody()}
        <Divider transparent />
        {getModalButtons()}
      </Modal>
    </>
  );
}
