/* istanbul ignore file */
import * as types from "@trolley/common-frontend";
import * as request from "services/request";
import store from "store";
import { OpCode, standardDispatch } from "store/dispatcher";
import { Query } from "utils/helpers";
import { computeID } from "./actionUtils";

export type BankInfo = types.LookupData["lookupData"];

export interface BankParameters {
  country?: string | null;
  currency?: string | null;
  iban?: string | null;
  swiftBic?: string | null;
  bankId?: string | null;
  branchId?: string | null;
}

export interface BankQuery extends Query, BankParameters {}

// TODO: take it to common-frontend?
export interface AccountVerificationQuery {
  currency: string;
  recipientId: string;
  country?: types.CountryCode.GB;
  name?: string;
  firstName?: string;
  lastName?: string;
  accountNumber?: string;
  sortCode?: string;
  iban?: string;
}

// TODO: take it to common-frontend
export enum BankAccountVerificationMatchType {
  FULL_MATCH = "full_match",
  CLOSE_MATCH = "close_match",
  NO_MATCH = "no_match",
}

// TODO: take it to common-frontend
export enum BankAccountVerificationReasonType {
  OKAY = "okay",
  WARNING = "warning",
  REJECTED = "rejected",
}

export interface AccountVerification {
  matchType: BankAccountVerificationMatchType;
  actualName: string | null;
  reasonCode: string;
  reason: string;
  reasonType: BankAccountVerificationReasonType;
}

export interface AccountVerificationResult {
  verification: AccountVerification;
}

export function loadBankInfo(query: BankQuery) {
  const { bankInfo } = store.getState();
  const id = computeID(query);

  if (bankInfo.fetchStatus[id] === undefined) {
    standardDispatch(OpCode.LOADING, "bankInfo", { id });
    request
      .POST<types.BankInfo.Result>("/v1/bank/lookup", { query })
      .then((res) => {
        standardDispatch(OpCode.DATA, "bankInfo", {
          id,
          data: res.body.lookupData,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "bankInfo", { id, errors });
      });
  }

  return id;
}

export function loadAccountVerification(query: AccountVerificationQuery, recipientType?: types.RecipientType) {
  const { accountVerification } = store.getState();
  const id = computeID({ ...query, recipientType });
  if (accountVerification.fetchStatus[id] === undefined) {
    standardDispatch(OpCode.LOADING, "accountVerification", { id });
    request
      .POST<AccountVerificationResult>("/v1/bank/account/verify", { query })
      .then((res) => {
        standardDispatch(OpCode.DATA, "accountVerification", {
          id,
          data: res.body.verification,
        });
      })
      .catch((errors) => {
        standardDispatch(OpCode.ERROR, "accountVerification", { id, errors });
      });
  }

  return id;
}
