import { RecipientType, SupportedTaxRegions, formatCountry, getRegionLabel } from "@trolley/common-frontend";
import React, { useEffect, useState } from "react";
import { useMerchant } from "store/hooks/merchant";
import { useRecipient } from "store/hooks/recipient";
import { stringifyAddress } from "utils/helpers";
import Wizard, { TaxProfileWizard, WizardPages } from "./Wizard";

const INDIVIDUAL_PATH = [
  WizardPages.GREETING_MESSAGE,
  WizardPages.CONFIRM_GOVERNMENT_PUBLIC_ENTITY,
  WizardPages.GDPR_MESSAGE,
  WizardPages.CONFIRM_RECIPIENT_TYPE,
  WizardPages.CONFIRM_RECIPIENT_NAME,
  WizardPages.CONFIRM_PRIMARY_ADDRESS,
  WizardPages.UPDATE_PRIMARY_ADDRESS,
  WizardPages.CONFIRM_VAT_NUMBER,
  WizardPages.ENTER_VAT_NUMBER,
  WizardPages.ENTER_TIN_NUMBER,
  WizardPages.CONFIRM_BIRTHPLACE,
  WizardPages.CONFIRM_DATE_OF_BIRTH,
  WizardPages.SUMMARY,
  WizardPages.TAX_PROFILE_COMPLETE,
];

const BUSINESS_PATH = [
  WizardPages.GREETING_MESSAGE,
  WizardPages.CONFIRM_GOVERNMENT_PUBLIC_ENTITY,
  WizardPages.GDPR_MESSAGE,
  WizardPages.CONFIRM_RECIPIENT_TYPE,
  WizardPages.CONFIRM_BUSINESS_NAME,
  WizardPages.CONFIRM_PRIMARY_ADDRESS,
  WizardPages.UPDATE_PRIMARY_ADDRESS,
  WizardPages.CONFIRM_VAT_NUMBER,
  WizardPages.ENTER_VAT_NUMBER,
  WizardPages.ENTER_TIN_NUMBER,
  WizardPages.ENTER_BUSINESS_NUMBER,
  WizardPages.SUMMARY,
  WizardPages.TAX_PROFILE_COMPLETE,
];

// The purpose of creating a higher level component for the wizard is for first making easier to
// maintain the logic for building the wizard path, and second, to allow in the future to have different
// tax profiles for each supported region
export default function OnBoardingTaxProfile() {
  const merchant = useMerchant();
  const recipient = useRecipient();
  const [path, setPath] = useState<WizardPages[] | null>(null);

  function isEnabledRegion(region: SupportedTaxRegions) {
    return !!merchant?.supportedTaxRegions?.[region];
  }

  function isOnlyEnabledRegion(region: SupportedTaxRegions) {
    if (!merchant) return false;

    const { supportedTaxRegions } = merchant;
    if (!supportedTaxRegions || !supportedTaxRegions[region]) return false;

    return Object.keys(supportedTaxRegions)
      .filter((s: SupportedTaxRegions) => s !== region)
      .every((s: SupportedTaxRegions) => !supportedTaxRegions[s]);
  }

  useEffect(() => {
    if (recipient && !path) {
      setPath(recipient.type === RecipientType.INDIVIDUAL ? INDIVIDUAL_PATH : BUSINESS_PATH);
    }
  }, [recipient]);

  const stringifiedPayoutAddress = stringifyAddress({
    ...recipient?.address,
    country: formatCountry(recipient?.address.country),
    region: getRegionLabel(recipient?.address.region, recipient?.address.country),
  }).trim();

  function updatePath(taxProfileWizard: TaxProfileWizard) {
    let updatedPath =
      taxProfileWizard.confirmedRecipientType === RecipientType.INDIVIDUAL ? [...INDIVIDUAL_PATH] : [...BUSINESS_PATH];

    if (isOnlyEnabledRegion(SupportedTaxRegions.AU)) {
      const governmentQuestionIndex = updatedPath.indexOf(WizardPages.CONFIRM_GOVERNMENT_PUBLIC_ENTITY);
      if (governmentQuestionIndex >= 0) {
        updatedPath = [
          ...updatedPath.slice(0, governmentQuestionIndex),
          ...updatedPath.slice(governmentQuestionIndex + 1),
        ];
      }
    }

    if (taxProfileWizard.confirmVATNumber === "no") {
      const enterVatIndex = updatedPath.indexOf(WizardPages.ENTER_VAT_NUMBER);
      if (enterVatIndex >= 0) {
        updatedPath = [...updatedPath.slice(0, enterVatIndex), ...updatedPath.slice(enterVatIndex + 1)];
      }
    }

    if (!isEnabledRegion(SupportedTaxRegions.EU)) {
      const confirmVatIndex = updatedPath.indexOf(WizardPages.CONFIRM_VAT_NUMBER);
      if (confirmVatIndex >= 0) {
        updatedPath = [...updatedPath.slice(0, confirmVatIndex), ...updatedPath.slice(confirmVatIndex + 1)];
      }
      const enterVatIndex = updatedPath.indexOf(WizardPages.ENTER_VAT_NUMBER);
      if (enterVatIndex >= 0) {
        updatedPath = [...updatedPath.slice(0, enterVatIndex), ...updatedPath.slice(enterVatIndex + 1)];
      }
    }
    if (
      taxProfileWizard?.confirmedRecipientType === RecipientType.INDIVIDUAL &&
      taxProfileWizard?.tinNumbers &&
      taxProfileWizard?.tinNumbers?.length > 0
    ) {
      const birthplaceIndex = updatedPath.indexOf(WizardPages.CONFIRM_BIRTHPLACE);
      if (birthplaceIndex >= 0) {
        updatedPath = [...updatedPath.slice(0, birthplaceIndex), ...updatedPath.slice(birthplaceIndex + 1)];
      }
    }
    if (taxProfileWizard.confirmAddress === "yes") {
      const updateAddressIndex = updatedPath.indexOf(WizardPages.UPDATE_PRIMARY_ADDRESS);
      if (updateAddressIndex >= 0) {
        updatedPath = [...updatedPath.slice(0, updateAddressIndex), ...updatedPath.slice(updateAddressIndex + 1)];
      }
    }
    if (!stringifiedPayoutAddress || stringifiedPayoutAddress === "-") {
      const confirmAddressIndex = updatedPath.indexOf(WizardPages.CONFIRM_PRIMARY_ADDRESS);
      if (confirmAddressIndex >= 0) {
        updatedPath = [...updatedPath.slice(0, confirmAddressIndex), ...updatedPath.slice(confirmAddressIndex + 1)];
      }
    }
    setPath([...updatedPath]);
  }

  return <>{path && <Wizard updatePath={updatePath} path={path} />}</>;
}
