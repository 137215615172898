import React, { useCallback, useEffect, useState } from "react";
import config from "config";
import { BankFieldType, Rules } from "@trolley/common-frontend";
import { Form2 as Form } from "components";
import { BankInfoDisplay } from "features";
import debounce from "lodash.debounce";
import { FIELDS_REQUIRING_BANK_LOOKUP } from "pages/PayoutMethod2/BankTransfer";
import { BankQuery } from "store/actions/bankInfo";
import { RecipientAccount } from "store/actions/recipient";
import { useBankInfo } from "store/hooks/bankInfo";
import { createUseStyle, css } from "style/classname";
import { useIntl } from "utils/context";
import { pick } from "utils/helpers";
import { BaseStatus } from "store/reducers/standardReducer";

interface Props {
  recipientAccount: RecipientAccount | undefined;
  countryRule: BankFieldType[];
  requiresAccountVerification?: boolean;
}

export default function BankInfoFormField(props: Props) {
  const { countryRule, recipientAccount, requiresAccountVerification } = props;
  const { formatMessage } = useIntl();
  const styledBankInfo = useStyledBankInfo();
  const [query, setQuery] = useState<BankQuery | undefined>();
  const { data: bankInfoLookupData, status: bankInfoStatus } = useBankInfo(query);
  const form = Form.useFormInstance();
  const country = Form.useWatch("country", form);
  const currency = Form.useWatch("currency", form);
  const iban = Form.useWatch("iban", form);
  const swiftBic = Form.useWatch("swiftBic", form);
  const bankId = Form.useWatch("bankId", form);
  const branchId = Form.useWatch("branchId", form);
  const accountNum = Form.useWatch("accountNum", form);

  useEffect(() => {
    if (!recipientAccount) {
      onValuesChange();
    }
  }, [country, currency, iban, swiftBic, bankId, branchId, accountNum]);

  useEffect(() => {
    if (bankInfoLookupData) {
      form.setFieldValue("bankInfo", bankInfoLookupData);
      form.validateFields(["bankInfo"]).catch(() => {
        //
      });
    }
  }, [bankInfoLookupData]);

  useEffect(() => {
    form.setFieldValue("bankInfoStatus", bankInfoStatus);
  }, [bankInfoStatus]);

  function onValuesChange() {
    form.resetFields(["bankInfo"]);
    setQuery(undefined);
    debounceSetQuery(countryRule);
  }

  const debounceSetQuery = useCallback(
    debounce((countryRule: Rules) => {
      if (countryRule.length) {
        const fieldsValue = form.getFieldsValue();
        const validValues = countryRule.every((rule) => {
          if (!FIELDS_REQUIRING_BANK_LOOKUP.includes(rule)) {
            return true;
          }

          if (!fieldsValue[rule]) {
            return false;
          }

          return !fieldsValue[rule]?.includes("*") || rule === "accountNum"; // we can allow any values for AccountNum;
        });

        const hasErrors = Object.values(form.getFieldsError(FIELDS_REQUIRING_BANK_LOOKUP)).some((v) => v.errors.length); // Errors on fields that matters to the bank lookup
        if (!hasErrors && validValues) {
          setQuery(pick(fieldsValue, FIELDS_REQUIRING_BANK_LOOKUP));
        }
      }
    }, 650),
    [form],
  );

  if (!countryRule.includes("bankCodeMappingId")) {
    if (recipientAccount) {
      // if there is a preexisting account, we don't don't allow to change the core bank info, so there's no need to validate
      return <BankInfoDisplay recipientAccount={recipientAccount} showAddress={!countryRule.includes("swiftBic")} />;
    }

    return (
      <>
        <Form.Item
          name="bankInfo"
          shouldUpdate
          rules={[
            {
              required: true,
              message: formatMessage({
                id: "containers.bankPayoutMethod.noLookupTooltip",
              }),
            },
          ]}
          className={styledBankInfo}
        >
          {(config.ENV === "production" || !requiresAccountVerification || bankInfoStatus === BaseStatus.LOADED) && (
            <BankInfoDisplay
              status={bankInfoStatus}
              recipientAccount={recipientAccount}
              showAddress={!countryRule.includes("swiftBic")}
            />
          )}
        </Form.Item>
        <Form.Item name="bankInfoStatus" />
      </>
    );
  }

  return null;
}

const useStyledBankInfo = createUseStyle(({ theme }) =>
  css`
    &.${theme.prefixCls}-form-item {
      margin-bottom: 0;
      .${theme.prefixCls}-form-item-control-input {
        min-height: 0;
      }
    }
  `(),
);
