import { BackButton, Button, Form, Grid, Tooltip } from "components";
import { PATHS } from "pages/App/routes";
import React, { Dispatch, SetStateAction } from "react";
import { useIntl } from "utils/context";
import { useHistory } from "react-router-dom";
import { RecipientAccount, updatePayoutMethod } from "store/actions/recipient";
import { useRecipient } from "store/hooks/recipient";

interface Props {
  account: RecipientAccount | undefined;
  disabled?: boolean;
  disabledTooltip?: string;
  busy: boolean;
  setBusy: Dispatch<SetStateAction<boolean>>;
  onSave?(): void;
  onSaveActivate?(): void;
  extras?: React.ReactNode;
}

export default function PayoutFooter({
  account,
  disabled,
  disabledTooltip,
  busy,
  setBusy,
  onSave,
  onSaveActivate,
  extras,
}: Props) {
  const recipient = useRecipient();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const submitLabel = formatMessage({
    id: account && account.id ? "common.save" : "common.add",
  });
  const showAddAndActivate = !account?.primary && recipient?.accounts.some((a) => !account || account.id !== a.id);

  async function onActivate() {
    if (recipient && account) {
      setBusy(true);
      try {
        await updatePayoutMethod(recipient.id, account.recipientAccountId, {
          primary: true,
        });
        history.push(PATHS.HOME);
      } catch (errors) {}
      setBusy(false);
    }
  }

  const footer = (
    <Grid direction="row-reverse" padding="small" style={{ marginTop: "16px" }} justify="space-between">
      <Form.Control shouldUpdate>
        {(control, meta, { isFieldsTouched }) => {
          return (
            <>
              {extras && <Grid.Item>{extras}</Grid.Item>}
              {showAddAndActivate && (
                <Grid.Item>
                  {account && !isFieldsTouched() ? (
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        await onActivate();
                      }}
                      size="large"
                      loading={busy}
                    >
                      {formatMessage({
                        id: "containers.accountSummary.buttons.makeActive",
                      })}
                    </Button>
                  ) : (
                    <Button
                      htmlType="submit"
                      type="primary"
                      onClick={onSaveActivate}
                      size="large"
                      loading={busy}
                      disabled={disabled}
                    >
                      {submitLabel}{" "}
                      {formatMessage({
                        id: "containers.paypalPayoutMethod.andActivate",
                      })}
                    </Button>
                  )}
                </Grid.Item>
              )}

              <Grid.Item>
                <Button
                  size="large"
                  htmlType="submit"
                  type={showAddAndActivate ? "default" : "primary"}
                  loading={busy}
                  onClick={onSave}
                  disabled={disabled}
                >
                  {submitLabel}
                </Button>
              </Grid.Item>
            </>
          );
        }}
      </Form.Control>

      <Grid.Item flex={extras ? undefined : "1"}>
        <BackButton />
      </Grid.Item>
    </Grid>
  );

  return disabled && disabledTooltip ? (
    <Tooltip placement="topRight" title={disabledTooltip} trigger={["hover"]}>
      {footer}
    </Tooltip>
  ) : (
    footer
  );
}
