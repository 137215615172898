import { getFtins, FTINType, CountryCode, RecipientType } from "@trolley/common-frontend";

const unsupportedFtinTypes: Partial<Record<CountryCode, string[]>> = {
  [CountryCode.AR]: ["cbu"],
  [CountryCode.AU]: ["tfn"],
  [CountryCode.IT]: ["aic"],
  [CountryCode.MX]: ["clabe"],
  [CountryCode.NO]: ["kontonr"],
};

export function getFilteredFtinsByCountry() {
  const fTinsByCountry: Record<string, FTINType> = getFtins();
  Object.keys(fTinsByCountry).forEach((countryCode: CountryCode) => {
    const unsupportedFtins = unsupportedFtinTypes[countryCode];
    if (unsupportedFtins) {
      unsupportedFtins.forEach((ftinType: string) => {
        delete fTinsByCountry[countryCode][ftinType];
      });
    }
  });

  return fTinsByCountry;
}

export function getFTinLabel(tinCountry: CountryCode, tinType: string | null | undefined) {
  const fTinsByCountry = getFilteredFtinsByCountry();
  if (!tinCountry || !tinType || tinType === "other" || !fTinsByCountry[tinCountry]) return "Other";
  const fTins: FTINType = fTinsByCountry[tinCountry];

  return fTins[tinType]?.localName ?? fTins[tinType]?.name ?? "";
}

export function filterFtinsByRecipientType(ftins: FTINType, recipientType: RecipientType) {
  Object.entries(ftins).forEach(([ftinType, value]) => {
    if (recipientType === RecipientType.INDIVIDUAL && !value.isIndividual) {
      delete ftins[ftinType];
    } else if (recipientType === RecipientType.BUSINESS && !value.isCompany) {
      delete ftins[ftinType];
    }
  });

  return ftins;
}
